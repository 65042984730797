<script setup lang="ts">
const defaultCookie = <CookieConsentBase>{
  version: (useRuntimeConfig()?.public?.cookie?.version ?? '1.0'),
  consent: false,
  optional: {
    performance: false,
    advertisement: false
  }
};

const cookie = useCookie<CookieConsentBase>((useRuntimeConfig()?.public?.cookie?.name ?? 'consent'), {
  default: () => (defaultCookie),
  sameSite: 'lax',
  maxAge: (60 * 60 * 24 * 31) // month
});

// New version release
if ((cookie?.value != null) && (cookie?.value?.version !== (useRuntimeConfig()?.public?.cookie?.version ?? '1.0')))
  cookie.value = defaultCookie;

const modal = computed<boolean>(() => !(cookie?.value?.consent ?? false));

const optional = ref<boolean>();
const performance = ref<Number | number>(cookie.value?.optional?.performance ? 1 : 0);
const advertisement = ref<Number | number>(cookie.value?.optional?.advertisement ? 1 : 0);

const confirm = (performance: boolean | Number | number = true, advertisement: boolean | Number | number = true) => {
  cookie.value = <CookieConsentBase>{
    version: (useRuntimeConfig()?.public?.cookie?.version ?? '1.0'),
    consent: true,
    optional: {
      performance: ((performance == 1) ? true : false) as boolean,
      advertisement: ((advertisement == 1) ? true : false) as boolean
    }
  };
};
</script>

<template>
  <BaseModal v-model="modal">
    <div
      :class="['bg-white rounded-lg shadow-xl p-6 text-center flex flex-col justify-center items-center gap-4 max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl']">
      <BaseText class="font-semibold"
        :size="(['xl', '2xl'].includes(useNuxtApp()?.$styleSize?.value) ? useNuxtApp()?.$styleSize?.value : 'lg')">
        <h1>
          Používanie cookies 🍪
        </h1>
      </BaseText>
      <BaseText class="flex flex-col gap-4">
        Táto webová stránka používa cookies na zlepšenie vášho zážitku a analýzu návštevnosti. Pokračovaním v
        prehliadaní stránky súhlasíte s ich používaním. <br /> Pre viac informácií nám napíšte mail alebo nás
        telefonicky kontaktujte.
      </BaseText>
      <BaseCollapse v-model="optional">
        <BaseBox rounded class="flex flex-col gap-2 border-2 text-left">
          <BaseBox class="grid gap-1">
            <BaseText>
              <h2 class="font-semibold">Nevyhnutné cookies</h2>
              <i>
                Tieto cookies sú potrebné pre správne fungovanie stránky a nemožno ich vypnúť v našich systémoch.
              </i>
            </BaseText>
            <BaseInput no-grid class="flex flex-row justify-between items-center gap-2" title="Funkčné cookie" type="checkbox" true-value="1"
              false-value="0" :value="1" disabled block />
          </BaseBox>
          <BaseBox class="grid gap-1 border">
            <BaseText>
              <h2 class="font-semibold">Voliteľné cookies</h2>
              <i>
                Tieto cookies nám pomáhajú zlepšiť váš zážitok a prispôsobiť obsah vašim preferenciám.
              </i>
            </BaseText>
            <BaseInput no-grid class="flex flex-row justify-between items-center gap-2" title="Výkonnostné cookie" type="checkbox"
              v-model="performance" true-value="1" false-value="0" block />
            <BaseInput no-grid class="flex flex-row justify-between items-center gap-2" title="Reklamné cookie" type="checkbox"
              v-model="advertisement" true-value="1" false-value="0" block />
          </BaseBox>
          <BaseButton @click="(e: Event) => confirm(performance, advertisement)" style-name="primary" block>Potvrdit
            výběr</BaseButton>
        </BaseBox>
      </BaseCollapse>

      <div class="flex flex-row gap-2">
        <BaseButton @click="(e: Event) => confirm()" style-name="primary">Súhlasím</BaseButton>
        <BaseButton @click="(e: Event) => optional = !optional" style-name="secondary">Nastavenie cookies</BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
